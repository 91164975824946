import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getBFY } from '../../APIs/functions'
import ProductCarousel from '../../Components/Carousel'
import { Spin } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { handleHotlinkClick } from '../../Utils/Gtag'

const BFYCarousel = () => {
  const queries = {
    retry: 1,
    refetchOnmount: true,
    refetchOnWindowFocus: false,
    cacheTime: 325000,
    staleTime: 300000
  };

  const bfyRef = useRef()

  const [searchParams] = useSearchParams();

  let upcCode = searchParams.get("upcCode");
  const client = searchParams.get("client");
  const domain = searchParams.get("domain")

  const [retryCount, setRetryCount] = useState(0);

  const { data, isLoading, isError, refetch } = useQuery(['BFYproducts', upcCode, client], () => getBFY({ upcCode, client }), queries)

  const products = !isLoading && !isError ? Array.isArray(data) ? data : data?.productRecommendedData : []

  useEffect(() => {
    if (!isLoading) {
      // Check if UPC code length is not 11
      if (upcCode.length !== 11) {
        // Check for errors and data availability
        if (isError || !data || !products || data?.status === 404 || data?.status === 500 || products.length === 0) {
          // Retry fetching data with modified UPC code
          if (upcCode.length === 12 && upcCode.startsWith('0')) {
            // Remove leading '0' from UPC code
            if (retryCount === 0) {
              upcCode = upcCode.substring(1);
              refetch().then(() => {
                setRetryCount(retryCount + 1);
              });
            } else {
              // Notify parent window about BFY unavailability
              console.log(`BFY not available for UPC: ${upcCode}`);
              window.parent.postMessage(
                { type: "bfy", message: "BFY not available" },
                "*"
              );
              if (bfyRef.current) {
                bfyRef.current.remove();
              }
              return;
            }
          } else {
            // Notify parent window about BFY unavailability
            console.log(`BFY not available for UPC: ${upcCode}`);
            window.parent.postMessage(
              { type: "bfy", message: "BFY not available" },
              "*"
            );
            if (bfyRef.current) {
              bfyRef.current.remove();
            }
            return;
          }
        }
      } else {
        // Check for errors and data availability
        if (isError || !products || !data || data?.status === 404 || data?.status === 500 || products.length === 0) {
          // Add leading '0' to UPC code
          if (retryCount === 0) {
            upcCode = "0" + upcCode;
            refetch().then(() => {
              setRetryCount(retryCount + 1);
            });
          } else {
            // Notify parent window about BFY unavailability
            console.log(`BFY not available for UPC: ${upcCode}`);
            window.parent.postMessage(
              { type: "bfy", message: "BFY not available" },
              "*"
            );
            if (bfyRef.current) {
              bfyRef.current.remove();
            }
            return;
          }
        }
      }
    }
  }, [data, isLoading, isError, retryCount]);

  return (
    <>
      {!isLoading && !isError && products ? <div className='container'>
        {products.length > 0 && <div className='row h-100 p-3'>
          <h4 className='text-center mb-4'>
            <span>Better-for-You Alternatives</span>
          </h4>
          {!isLoading && !isError ? <ProductCarousel products={products} client={client} domain={domain} /> : <Spin />}
          <a onClick={handleHotlinkClick} href="https://about.greenchoicenow.com/" rel="noreferrer" target="_blank" className='branding mt-3'>
            Powered by GreenChoice
            <img src="./images/gclogo.svg" alt="Greenchoice" />
          </a>
        </div>}
      </div> : null
      }
    </>
  )
}

export default BFYCarousel