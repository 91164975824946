import {
  getOtherProductData,
  getProductData,
} from "../../APIs/functions";
import Chart from "../../Components/Chart";
import { useSearchParams } from "react-router-dom";
import "./gsChart.scss";
import { useQuery } from "react-query";
import { useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import { handleHotlinkClick } from "../../Utils/Gtag";
import ChartSkeleton from "../GSChartNative/chartSkeleton";
import { sampleProduct } from "../GSChartNative/sampleData";

const GSchart = () => {
  const queries = {
    retry: 1,
    refetchOnmount: true,
    refetchOnWindowFocus: false,
    cacheTime: 325000,
    staleTime: 300000
  };

  const [searchParams] = useSearchParams();
  let upcCode = searchParams.get("upcCode");
  const label = searchParams.get("chartLabel");
  const labelSize = searchParams.get("chartLabelSize");

  const [productName, setProductName] = useState("");
  const [retryCount, setRetryCount] = useState(0);

  const chartRef = useRef(null);

  const {
    data: product,
    isLoading: productDataLoading,
    isError: productDataError,
    refetch,
    isFetching,
  } = useQuery(
    ["productData", upcCode],
    () => getProductData({ upcCode }),
    queries
  );

  const {
    data: productOtherData,
    isLoading: productOtherDataLoading,
    isError: productOtherDataError,
  } = useQuery(
    ["productOtherData", productName],
    () => getOtherProductData({ productName }),
    {
      ...queries,
      enabled: productName !== "",
    }
  );

  useEffect(() => {
    if (!productDataLoading) {

      // Check UPC code validity and handle errors
      if (upcCode.length !== 11 && upcCode.length !== 12) {
        console.log(`GS Chart is not available for UPC: ${upcCode}`);
        window.parent.postMessage(
          { type: "chart", message: "Chart not available" },
          "*"
        );
        if (chartRef.current) {
          chartRef.current.remove();
        }
        return;
      }

      // Check if product data is available
      if (productDataError || !product) {
        // Retry fetching data with modified UPC code
        if (retryCount === 0) {
          let modifiedUpcCode = upcCode;
          if (upcCode.length === 12 && upcCode.startsWith("0")) {
            modifiedUpcCode = upcCode.substring(1);
          } else if (upcCode.length !== 11 && upcCode.length !== 12) {
            console.log(`GS Chart is not available for UPC: ${upcCode}`);
            window.parent.postMessage(
              { type: "chart", message: "Chart not available" },
              "*"
            );
            if (chartRef.current) {
              chartRef.current.remove();
            }
            return;
          } else {
            modifiedUpcCode = "0" + upcCode;
          }
          upcCode = modifiedUpcCode; // Update UPC code
          refetch().then(() => {
            setRetryCount(retryCount + 1);
          });
        } else {
          // Notify parent window about chart unavailability
          console.log(`GS Chart is not available for UPC: ${upcCode}`);
          window.parent.postMessage(
            { type: "chart", message: "Chart not available" },
            "*"
          );
          if (chartRef.current) {
            chartRef.current.remove();
          }
        }
      }

      setProductName(product?.productNameURL);
    }
  }, [productDataLoading, productDataError, product, upcCode, retryCount]);

  useEffect(() => {
    const sendHeightToParent = () => {
      if (chartRef.current) {
        const height =
          (chartRef.current.scrollHeight || chartRef.current.offsetHeight) + 20;
        window.parent.postMessage(
          { type: "chartHeight", iframeHeight: height },
          "*"
        );
      }
    };

    sendHeightToParent();

    const observer = new MutationObserver(() => {
      sendHeightToParent();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true
    });

    return () => {
      observer.disconnect();
      sendHeightToParent.cancel();
    };
  }, []);


  return (
    <div ref={chartRef}>
      {product && productOtherData &&
        <div id={label ? "gsChartExport" : "gsChartExportWithoutHeight"}>
          {label && (
            <div>
              <h2
                className={`${labelSize} chart-heading text-center pt-2 mb-0`}
              >
                The GreenScore<sub className="copyright-icon">®</sub> rating
              </h2>
              <a
                href="https://about.greenchoicenow.com/"
                rel="noreferrer"
                target="_blank"
                className="branding"
                onClick={handleHotlinkClick}
              >
                Powered by GreenChoice
                <img src="./images/gclogo.svg" alt="Greenchoice" />
              </a>
            </div>
          )}
          <Chart label={label} product={product} additionalData={productOtherData} />
        </div>
      }
    </div>
  );
};

export default GSchart;
